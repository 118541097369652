
  export const colours = [
    "rgba(128,0,0,0.75)",
    "rgba(255,0,0,0.75)",
    "rgba(255,127,80,0.75)",
    "rgba(255,140,0,0.75)",
    "rgba(255,215,0,0.75)",
    "rgba(238,232,170,0.75)",
    "rgba(128,128,0,0.75)",
    "rgba(255,255,0,0.75)",
    "rgba(85,107,47,0.75)",
    "rgba(127,255,0,0.75)",
    "rgba(32,178,170,0.75)",
    "rgba(47,79,79,0.75)",
    "rgba(0,255,255,0.75)",
    "rgba(54,162,235,0.75)",
    "rgba(25,25,112,0.75)",
    "rgba(138,43,226,0.75)",
    "rgba(186,85,211,0.75)",
    "rgba(255,0,255,0.75)",
    "rgba(199,21,133,0.75)",
    "rgba(255,105,180,0.75)",
    "rgba(255,182,193,0.75)",
    "rgba(250,235,215,0.75)",
    "rgba(139,69,19,0.75)",
    "rgba(210,105,30,0.75)",
    "rgba(222,184,135,0.75)",
    "rgba(188,143,143,0.75)",
    "rgba(255,206,86,0.75)",
    "rgba(255,228,225,0.75)",
    "rgba(112,128,144,0.75)",
    "rgba(240,248,255,0.75)",
    "rgba(128,0,0,0.75)",
    "rgba(255,0,0,0.75)",
    "rgba(255,127,80,0.75)",
    "rgba(255,140,0,0.75)",
    "rgba(255,215,0,0.75)",
    "rgba(238,232,170,0.75)",
    "rgba(128,128,0,0.75)",
    "rgba(255,255,0,0.75)",
    "rgba(85,107,47,0.75)",
    "rgba(127,255,0,0.75)",
    "rgba(32,178,170,0.75)",
    "rgba(47,79,79,0.75)",
    "rgba(0,255,255,0.75)",
    "rgba(54,162,235,0.75)",
    "rgba(25,25,112,0.75)",
    "rgba(138,43,226,0.75)",
    "rgba(186,85,211,0.75)",
    "rgba(255,0,255,0.75)",
    "rgba(199,21,133,0.75)",
    "rgba(255,105,180,0.75)",
    "rgba(255,182,193,0.75)",
    "rgba(250,235,215,0.75)",
    "rgba(139,69,19,0.75)",
    "rgba(210,105,30,0.75)",
    "rgba(222,184,135,0.75)",
    "rgba(188,143,143,0.75)",
    "rgba(255,206,86,0.75)",
    "rgba(255,228,225,0.75)",
    "rgba(112,128,144,0.75)",
    "rgba(240,248,255,0.75)",
  ]