import "./App.css";

import MainPage from "./Components/MainPage.js";
import Navbar from "./Components/Navbar.js";
import Background from "./Components/Background.js";

import { HashRouter as Router, Route } from "react-router-dom";

import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";




const firebaseConfig = {
  apiKey: "AIzaSyDrCf_bz1StGWJBJKs9eGQrb6mdEZQ6AGA",
  authDomain: "my-portfolio-e5a5e.firebaseapp.com",
  projectId: "my-portfolio-e5a5e",
  storageBucket: "my-portfolio-e5a5e.appspot.com",
  messagingSenderId: "139927408571",
  appId: "1:139927408571:web:e7620b3c823aaa39163e9e",
  measurementId: "G-RYX370EF7N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

function App() {
  return (
    <Router>
      <div className="App">
        <Background />
        <Navbar />
        <Route exact={true} path="/" render={() => <MainPage />} />
      </div>
    </Router>
  );
}

export default App;
